.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  height: 100px;
}

body {
  background-color: #282c34;
  color: white;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 100% !important;
  margin: 0 !important;
}
.partnercontainer {
  text-align: center;
}
.partnercontainer .title {
  margin: 10px auto;
  font-size: larger;
}
.partner {
  width: 120px;
  height: 120px;
  opacity: .8;
  display: inline-block !important;
  margin: 2em 2em 0px 2em;
  padding: 0 !important;
}

.partner:hover {
  opacity: 1;
}
.partner img {
  border-radius: 15px;
}
.partner a {
  color: #fff;
}
