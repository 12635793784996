.header {
  text-align: left;
  min-height: 100px;
}
.header img {
  margin: 0 1em;
}
.header .titlebar {
  padding-top: 20px;
  vertical-align: top;
  display: inline-block;
  height: 80px;

  min-width: 800px;
}
.titlebar .title {
  font-size: 2em;
  display: block;
}
.description {
  display: block;
  opacity: 0.5;
  margin-top: 15px;
  font-size: small;
}
.descriptiontwo {
  display: block;
  opacity: 0.5;
  margin-top: -5px;
  font-size: small;
}

.container{
  width: 80%;
  margin: 1em auto;
}
.container td {
  padding: 5px 10px;
  text-align: left;
}